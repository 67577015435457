import React, { useEffect, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";

import { SUBMITTED_FORMS } from "../../../shared/apiUrls";
import { fontColor } from "../../../shared/hoc/pagesWeb/incidentReporter/constants";
import { apiGetAuth } from "../../../shared/hoc/pagesWeb/incidentReporter/utils";
import PageLayout from "../../components/layouts/PageLayout";
import LSSpinner from "../../components/spinner/LSSpinner";
import LSText, { textVariant } from "../../components/text/LSText";
import { pageAppTypes, urlRoutes } from "../constants";
import { getFormattedDate } from "../utils";

const pageTitle = "Submitted Reports";

export default function IncidentsReports() {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);

  const getForms = async () => {
    setLoading(true);
    const response = await apiGetAuth(SUBMITTED_FORMS);
    console.log("Here is the response", response);
    setSubmissions(response);
    setLoading(false);
  };

  const handlePressForm = (uuid) => {
    console.log(uuid);
    window.location.href = `${urlRoutes.submittedFormView}?report=${uuid}`;
  };

  useEffect(() => {
    getForms();
  }, []);

  useEffect(() => {
    console.log({ submissions });
  }, [submissions]);

  return (
    <PageLayout pageAppType={pageAppTypes.incidentReports}>
      {loading ? (
        <View style={styles.spinnerContainerStyles}>
          <LSSpinner />
        </View>
      ) : (
        <View style={{ width: "100%" }}>
          <LSText
            variant={textVariant.h1}
            text={pageTitle}
            color="white"
            customStyles={{ textAlign: "center" }}
          />

          {submissions?.map((submission) => {
            const { name } = submission.form;
            const { submitDate } = submission;
            const { firstName, lastName } = submission.user;
            const date = getFormattedDate(new Date(submitDate));
            return (
              <Pressable onPress={() => handlePressForm(submission.uuid)}>
                <View style={styles.submissionContainer}>
                  <View style={styles.submissionContainerLeft}>
                    <LSText
                      variant={textVariant.h4}
                      text={name}
                      color="#ffffff"
                    />
                    <LSText
                      variant={textVariant.text}
                      text={`${lastName}, ${firstName}`}
                      color="#ffffff"
                    />
                  </View>
                  <View style={styles.submissionContainerRight}>
                    <LSText
                      variant={textVariant.h6}
                      text={date}
                      color="#ffffff"
                    />
                  </View>
                </View>
              </Pressable>
            );
          })}
        </View>
      )}
    </PageLayout>
  );
}

const styles = StyleSheet.create({
  bodyContainerStyles: {
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  spinnerContainerStyles: {
    height: "80vh",
    justifyContent: "center",
  },
  submissionContainer: {
    borderBottomColor: fontColor,
    borderBottomWidth: 1,
    flexDirection: "row",
    paddingTop: 16,
    paddingBottom: 8,
  },
  submissionContainerLeft: {
    flex: 1,
  },
  submissionContainerRight: {
    width: 90,
  },
});
