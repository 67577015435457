import React from "react";
import { View } from "react-native";

import { pageNames, urlRoutes } from "./constants";
import { isWeb } from "../../utils/environments";
import LSButton, { buttonVariants } from "../components/buttons/LSButton";
import PageLayout from "../components/layouts/PageLayout";
import LSText, { textVariant } from "../components/text/LSText";
import { mockNavigationWeb } from "../utils/mockNavigationWeb";

const welcomeText = "Welcome to LinkStep";

export default function Splash() {
  const { useNavigation } = isWeb
    ? mockNavigationWeb
    : // eslint-disable-next-line global-require
      require("@react-navigation/native");

  const navigation = useNavigation();

  const handleLoginPress = () => {
    if (isWeb) {
      window.location.href = urlRoutes.login;
    } else {
      navigation.navigate("login");
    }
  };

  const handleRegisterPress = () => {
    if (isWeb) {
      window.location.href = urlRoutes.register;
    } else {
      navigation.navigate(pageNames.register);
    }
  };

  return (
    <PageLayout center>
      <View>
        <LSText text={welcomeText} variant={textVariant.h1} />
        <LSButton
          text="Login"
          onPress={handleLoginPress}
          variant={buttonVariants.primary}
        />
        <LSButton
          text="Register"
          onPress={handleRegisterPress}
          variant={buttonVariants.secondary}
        />
      </View>
    </PageLayout>
  );
}
