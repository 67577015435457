import { FontAwesome } from "@expo/vector-icons";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { blue } from "../../../theme/colors";
import { getFormattedDate } from "../../utils/utils";

export default function FileViewer({ file }) {
  console.log({ file });
  const [preview, setPreview] = useState();
  const fileDate = new Date(file.lastModified);
  console.log({ fileDate });

  const _file = new FileReader();

  _file.onload = () => {
    console.log("file", _file.result);
    setPreview(_file.result);
  };

  _file.readAsDataURL(file);

  return (
    <Box sx={{ display: "flex", flexDirection: "row", marginTop: "12px" }}>
      <Box sx={{ width: "80px" }}>
        {file.type === "image/jpeg" || file.type === "image/png" ? (
          <img
            src={preview}
            alt="uploaded file"
            style={{
              maxHeight: "80px",
              maxWidth: "80px",
              borderRadius: "8px",
            }}
          />
        ) : (
          <FontAwesome name="file-text-o" size={80} color={blue[700]} />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "12px",
          flex: 1,
        }}
      >
        <Typography level="h1">
          <b>Name:</b> {file.name}
        </Typography>
        <Typography level="h1">
          <b>File Type:</b> {file.type}
        </Typography>
        <Typography level="h1">
          <b>Last Modified:</b> {getFormattedDate(fileDate)}
        </Typography>
      </Box>
    </Box>
  );
}

FileViewer.propTypes = {
  file: PropTypes.object.isRequired,
};
