import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";

import { _renewTokens } from "../../../../context/AuthContext";
import { localStorageKeys } from "../../../../context/constants";
import {
  CREATE_NEW_FORM,
  GET_MY_FORM,
  GET_MY_FORMS,
  GET_MY_FORMS_SUMMARY,
  SUBMIT_FORM,
  UPDATE_USER_NOTIFICATIONS,
} from "../../../apiUrls";

export const formErrorResponse = "There was an error generating your form.";

export const apiSubmitForm = async (formSubmit) => {
  try {
    const jwt = await AsyncStorage.getItem(localStorageKeys.accessTokenKey);
    const response = await axios
      .post(SUBMIT_FORM, formSubmit, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `bearer ${jwt}`,
        },
      })
      .catch((e) => httpErrorHandlerPost(e, formSubmit));
    return response.data;
  } catch (err) {
    return err;
  }
};

export const apiGenerateNewForm = async (formBody) => {
  try {
    const jwt = await AsyncStorage.getItem(localStorageKeys.accessTokenKey);
    const response = await axios
      .post(CREATE_NEW_FORM, formBody, {
        headers: {
          authorization: `bearer ${jwt}`,
        },
      })
      .catch((e) => httpErrorHandlerPost(e, formBody));
    return response.data;
  } catch (err) {
    return err;
  }
};

export const apiPostUserQANotifications = async (formID, formBody) => {
  try {
    const jwt = await AsyncStorage.getItem(localStorageKeys.accessTokenKey);
    const response = await axios
      .post(`${UPDATE_USER_NOTIFICATIONS}/${formID}`, formBody, {
        headers: {
          authorization: `bearer ${jwt}`,
        },
      })
      .catch((e) => httpErrorHandlerPost(e, formBody));
    return response.data;
  } catch (err) {
    return err;
  }
};

export const apiGetForms = async () => {
  try {
    const jwt = await AsyncStorage.getItem(localStorageKeys.accessTokenKey);
    const response = await axios
      .get(GET_MY_FORMS, {
        headers: {
          authorization: `bearer ${jwt}`,
        },
      })
      .catch(httpErrorHandlerGet);
    return response.data;
  } catch (e) {
    return e;
  }
};

export const apiGetPDFDownload = async () => {
  try {
    const jwt = await AsyncStorage.getItem(localStorageKeys.accessTokenKey);
    const response = await axios
      .get(GET_MY_FORMS, {
        responseType: "arraybuffer",
        headers: {
          authorization: `bearer ${jwt}`,
        },
      })
      .then((_response) => {
        console.log({ _response });
        const url = window.URL.createObjectURL(new Blob([_response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "receipt.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch(httpErrorHandlerGet);
    return response.data;
  } catch (e) {
    return e;
  }
};

export const apiGetFormsSummary = async () => {
  try {
    const jwt = await AsyncStorage.getItem(localStorageKeys.accessTokenKey);
    const response = await axios
      .get(GET_MY_FORMS_SUMMARY, {
        headers: {
          authorization: `bearer ${jwt}`,
        },
      })
      .catch(httpErrorHandlerGet);
    return response.data;
  } catch (e) {
    return e;
  }
};

export const apiGetForm = async (formID) => {
  try {
    const jwt = await AsyncStorage.getItem(localStorageKeys.accessTokenKey);
    const response = await axios
      .get(`${GET_MY_FORM}/${formID}`, {
        headers: {
          authorization: `bearer ${jwt}`,
        },
      })
      .catch(httpErrorHandlerGet);
    return response.data;
  } catch (e) {
    return e;
  }
};

export const httpErrorHandlerPost = async (error, body) => {
  const { statusText, responseURL } = error.request;
  if (statusText === "Unauthorized") {
    const newTokens = await _renewTokens();
    const response = await axios.post(responseURL, body, {
      headers: {
        authorization: `bearer ${newTokens.accessToken}`,
      },
    });
    return response;
  }
  return { data: "This is the new response" };
};

export const httpErrorHandlerGet = async (error) => {
  const { statusText, responseURL } = error.request;
  if (statusText === "Unauthorized") {
    const newTokens = await _renewTokens();
    const response = await axios.get(responseURL, {
      headers: {
        authorization: `bearer ${newTokens.accessToken}`,
      },
    });
    return response;
  }
  return { data: "This is the new response" };
};

export const apiGetAuth = async (url) => {
  try {
    const jwt = await AsyncStorage.getItem(localStorageKeys.accessTokenKey);
    const response = await axios
      .get(url, {
        headers: {
          authorization: `bearer ${jwt}`,
        },
      })
      .catch(httpErrorHandlerGet);
    return response.data;
  } catch (e) {
    console.log("error", e);
    return e;
  }
};

export const apiPostAuth = async (url, body) => {
  try {
    const jwt = await AsyncStorage.getItem(localStorageKeys.accessTokenKey);
    const response = await axios
      .post(url, body, {
        headers: {
          authorization: `bearer ${jwt}`,
        },
      })
      .catch((e) => httpErrorHandlerPost(e, body));
    return response.data;
  } catch (err) {
    return formErrorResponse;
  }
};
