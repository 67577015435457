// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuid } from "uuid";

export const pageBreakPointValues = {
  base: 0,
  sm: 390,
  md: 768,
  lg: 1024,
  xl: 1512,
};

export const pageNames = {
  splash: "splash",
  home: "home",
  login: "login",
  register: "register",
};

export const urlRoutes = {
  splash: "/",
  home: "/home",
  login: "/login",
  register: "/register",
  incidentsDashboard: "/incidents-dashboard",
  incidentsForms: "/incidents-forms",
  incidentsReports: "/incidents-reports",
  incidentsReportsMapping: "/incidents-reports-mapping",
  incidentsReportsEditing: "/incidents-reports-editing",
  incidentsReportsPreview: "/incidents-form-preview",
  submittedFormView: "/submitted-report-view",
  form: "/form",
  locationsDashboard: "/locations-dashboard",
  locationsSettings: "/locations-settings",
  usersDashboard: "/users-dashboard",
  usersSettings: "/users-settings",
  setPassword: "/set-password",
};

export const fontFamilyTypes = {
  fontAwesome: "fontAwesome",
  fontAwesome5: "fontAwesome5",
  foundation: "foundation",
  ionIcons: "ionIcons",
  octIcons: "octIcons",
  materialCommunityIcons: "materialCommunityIcons",
};

export const customContextMenuFunctionCalls = {
  addNewNextQuestionClick: "handleAddNewNextQuestionClick",
  duplicateNextQuestionClick: "handleDuplicateQuestionClick",
  deleteQuestionClick: "handleDeleteQuestionClick",
  editQuestionClick: "handleEditQuestionClick",
  userNotificationsClick: "handleUserNotificationsClick",
};

export const customQuestionContextMenu = [
  {
    text: "Add New Next Question",
    iconName: "plus",
    iconFamily: fontFamilyTypes.fontAwesome,
    functionCall: customContextMenuFunctionCalls.addNewNextQuestionClick,
  },
  {
    text: "Duplicate Next Existing Question",
    iconName: "duplicate",
    iconFamily: fontFamilyTypes.ionIcons,
    functionCall: customContextMenuFunctionCalls.duplicateNextQuestionClick,
  },
  {
    text: "Edit Question",
    iconName: "edit",
    iconFamily: fontFamilyTypes.fontAwesome,
    functionCall: customContextMenuFunctionCalls.editQuestionClick,
  },
  {
    text: "Delete Question",
    iconName: "trash",
    iconFamily: fontFamilyTypes.fontAwesome,
    functionCall: customContextMenuFunctionCalls.deleteQuestionClick,
  },
  {
    text: "Users Notifications",
    iconName: "users",
    iconFamily: fontFamilyTypes.fontAwesome,
    functionCall: customContextMenuFunctionCalls.userNotificationsClick,
  },
];

export const customAnswerContextMenu = [
  {
    text: "Add Contingent Question",
    iconName: "plus",
    iconFamily: fontFamilyTypes.fontAwesome,
    functionCall: customContextMenuFunctionCalls.addNewNextQuestionClick,
  },
  {
    text: "Duplicate Existing Sub Question",
    iconName: "duplicate",
    iconFamily: fontFamilyTypes.ionIcons,
    functionCall: customContextMenuFunctionCalls.duplicateNextQuestionClick,
  },
  {
    text: "Users Notifications",
    iconName: "users",
    iconFamily: fontFamilyTypes.fontAwesome,
    functionCall: customContextMenuFunctionCalls.userNotificationsClick,
  },
];

export const homeApps = [
  {
    id: uuid(),
    title: "Users",
    iconName: "users",
    baseRoute: urlRoutes.usersDashboard,
    iconFamily: fontFamilyTypes.fontAwesome,
  },
  {
    id: uuid(),
    title: "Locations",
    iconName: "office-building-cog",
    baseRoute: urlRoutes.locationsDashboard,
    iconFamily: fontFamilyTypes.materialCommunityIcons,
  },
  {
    id: uuid(),
    title: "Incident Tracker",
    iconName: "alert",
    baseRoute: urlRoutes.incidentsDashboard,
    iconFamily: fontFamilyTypes.foundation,
  },
  {
    id: uuid(),
    title: "Receipt Tracker",
    iconName: "truck",
    baseRoute: urlRoutes.incidentsDashboard,
    iconFamily: fontFamilyTypes.fontAwesome,
  },
  {
    id: uuid(),
    title: "Photo Gallery",
    iconName: "photo",
    baseRoute: urlRoutes.incidentsDashboard,
    iconFamily: fontFamilyTypes.fontAwesome,
  },
];

export const pageAppTypes = {
  incidentReports: "incidentReports",
  locations: "locations",
  users: "users",
};

export const pageTypeComponents = {
  [pageAppTypes.incidentReports]: {
    footerButtons: [
      {
        id: uuid(),
        name: "Home",
        iconName: "home",
        urlRoute: urlRoutes.home,
      },
      {
        id: uuid(),
        name: "Dashboard",
        iconName: "dashboard",
        urlRoute: urlRoutes.incidentsDashboard,
      },
      {
        id: uuid(),
        name: "Forms",
        iconName: "file-text-o",
        urlRoute: urlRoutes.incidentsForms,
      },
      {
        id: uuid(),
        name: "Reports",
        iconName: "files-o",
        urlRoute: urlRoutes.incidentsReports,
      },
      {
        id: uuid(),
        name: "Settings",
        iconName: "gear",
        urlRoute: urlRoutes.home,
      },
    ],
  },
  [pageAppTypes.locations]: {
    footerButtons: [
      {
        id: uuid(),
        name: "Home",
        iconName: "home",
        urlRoute: urlRoutes.home,
      },
      {
        id: uuid(),
        name: "Dashboard",
        iconName: "dashboard",
        urlRoute: urlRoutes.locationsDashboard,
      },
      {
        id: uuid(),
        name: "Manage",
        iconName: "users-cog",
        urlRoute: urlRoutes.locationsSettings,
        iconFamily: fontFamilyTypes.fontAwesome5,
      },
    ],
  },
  [pageAppTypes.users]: {
    footerButtons: [
      {
        id: uuid(),
        name: "Home",
        iconName: "home",
        urlRoute: urlRoutes.home,
      },
      {
        id: uuid(),
        name: "Dashboard",
        iconName: "dashboard",
        urlRoute: urlRoutes.usersDashboard,
      },
      {
        id: uuid(),
        name: "Manage",
        iconName: "users-cog",
        urlRoute: urlRoutes.usersSettings,
        iconFamily: fontFamilyTypes.fontAwesome5,
      },
    ],
  },
};

export const emptyAnswer = {
  text: "",
  selectedDate: null,
  questionUUID: "",
  answerOptionUUID: "",
  answerOptionIDs: [],
};
