import { FontAwesome } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";

import { GET_USERS } from "../../../shared/apiUrls";
import { apiGetAuth } from "../../../shared/hoc/pagesWeb/incidentReporter/utils";
import { fontColorLight } from "../../../theme/colors";
import PageLayout from "../../components/layouts/PageLayout";
import AddUserModal from "../../components/modals/AddUserModal";
import LSSpinner from "../../components/spinner/LSSpinner";
import LSText, { textVariant } from "../../components/text/LSText";
import { pageAppTypes } from "../constants";

const pageTitle = "Users Settings";
// const newUserObject = {
//   firstName: "",
//   lastName: "",
//   email: "",
//   phone: "",
//   position: "",
//   role: "",
// };

// const userErrorsObject = {
//   firstName: false,
//   lastName: false,
//   email: false,
//   phone: false,
//   position: false,
//   role: false,
// };

export default function UsersSettings() {
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const getUsers = async () => {
    const results = await apiGetAuth(GET_USERS);
    setUsers(results);
    setLoading(false);
  };

  const handleAddNewUser = () => {
    setAddModalVisible(true);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <PageLayout pageAppType={pageAppTypes.users}>
      <View style={styles.headerContainerStyles}>
        <LSText
          variant={textVariant.h1}
          text={pageTitle}
          color={fontColorLight}
        />
        <Pressable onPress={handleAddNewUser}>
          <FontAwesome name="plus" size={25} color={fontColorLight} />
        </Pressable>
      </View>
      {loading ? (
        <LSSpinner />
      ) : (
        <View style={{ width: "100%" }}>
          {users?.map((user) => {
            const { uuid, firstName, lastName, position, active } = user;
            const inactiveText =
              active === null ? "Pending Invitation" : "Inactive";
            return (
              <Pressable
                key={uuid}
                onPress={() => console.log("show user profile")}
              >
                <View style={styles.submissionContainer}>
                  <View style={styles.submissionContainerLeft}>
                    <LSText
                      variant={textVariant.h4}
                      text={`${lastName}, ${firstName}`}
                      color="#ffffff"
                      customStyles={{ marginBottom: 8 }}
                    />
                    <LSText
                      variant={textVariant.text}
                      text={`${active ? "Active" : inactiveText}`}
                      color="#ffffff"
                      customStyles={{ marginBottom: 8 }}
                    />
                  </View>
                  <View>
                    <LSText
                      variant={textVariant.h4}
                      text={position}
                      color="#ffffff"
                      customStyles={{ marginBottom: 8 }}
                    />
                  </View>
                </View>
              </Pressable>
            );
          })}
        </View>
      )}
      <AddUserModal
        setUsers={setUsers}
        addModalVisible={addModalVisible}
        setAddModalVisible={setAddModalVisible}
      />
    </PageLayout>
  );
}

const styles = StyleSheet.create({
  spinnerContainerStyles: {
    height: "80vh",
    justifyContent: "center",
  },
  headerContainerStyles: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  buttonsContainer: {
    flexDirection: "row",
    width: "100%",
  },
  inputStyles: { margin: 0, marginBottom: 8 },
  submissionContainer: {
    borderBottomColor: fontColorLight,
    borderBottomWidth: 1,
    flexDirection: "row",
    paddingTop: 16,
    paddingBottom: 8,
  },
  submissionContainerLeft: {
    flex: 1,
  },
});
