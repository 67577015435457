import React from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Text, View } from "react-native";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "../src/pages/Home";
import Login from "../src/pages/Login";
import Register from "../src/pages/Register";
import Splash from "../src/pages/Splash";
import { urlRoutes } from "../src/pages/constants";
import IncidentsFormPreview from "../src/pages/incidents/IncidentFormPreview";
import IncidentsFormSubmit from "../src/pages/incidents/IncidentReportSubmit";
import IncidentReportsEditing from "../src/pages/incidents/IncidentReportsEditing";
import IncidentsReportsMapping from "../src/pages/incidents/IncidentReportsMapping";
import IncidentsDashboard from "../src/pages/incidents/IncidentsDashboard";
import IncidentsForms from "../src/pages/incidents/IncidentsForms";
import IncidentsReports from "../src/pages/incidents/IncidentsReports";
import SubmittedFormView from "../src/pages/incidents/SubmittedFormView";
import LocationsDashboard from "../src/pages/locations/LocationsDashboard";
import LocationsSettings from "../src/pages/locations/LocationsSettings";
import SetPassword from "../src/pages/users/SetPassword";
import UsersDashboard from "../src/pages/users/UsersDashboard";
import UsersSettings from "../src/pages/users/UsersSettings";

export default function MainWebNavigator() {
  // const { isLoggedIn } = useUser();
  return (
    <Router>
      <Routes>
        {true ? (
          <>
            <Route exact path={urlRoutes.splash} element={<Splash />} />
            <Route exact path={urlRoutes.home} element={<Home />} />
            <Route exact path={urlRoutes.login} element={<Login />} />
            <Route exact path={urlRoutes.register} element={<Register />} />
            <Route
              exact
              path={urlRoutes.incidentsDashboard}
              element={<IncidentsDashboard />}
            />
            <Route
              exact
              path={urlRoutes.incidentsForms}
              element={<IncidentsForms />}
            />
            <Route
              exact
              path={urlRoutes.incidentsReports}
              element={<IncidentsReports />}
            />
            <Route
              exact
              path={urlRoutes.incidentsReportsMapping}
              element={<IncidentsReportsMapping />}
            />
            <Route
              exact
              path={urlRoutes.incidentsReportsEditing}
              element={<IncidentReportsEditing />}
            />
            <Route
              exact
              path={urlRoutes.incidentsReportsPreview}
              element={<IncidentsFormPreview />}
            />
            <Route
              exact
              path={urlRoutes.submittedFormView}
              element={<SubmittedFormView />}
            />
            <Route
              exact
              path={urlRoutes.form}
              element={<IncidentsFormSubmit />}
            />
            <Route
              exact
              path={urlRoutes.locationsDashboard}
              element={<LocationsDashboard />}
            />
            <Route
              exact
              path={urlRoutes.locationsSettings}
              element={<LocationsSettings />}
            />
            <Route
              exact
              path={urlRoutes.usersDashboard}
              element={<UsersDashboard />}
            />
            <Route
              exact
              path={urlRoutes.usersSettings}
              element={<UsersSettings />}
            />
            <Route
              exact
              path={urlRoutes.setPassword}
              element={<SetPassword />}
            />
          </>
        ) : (
          <>
            <View>
              <Text>Hello</Text>
            </View>
            {/* <Route exact path={pageRoutes.register} element={<Register />} />
            <Route exact path={pageRoutes.notFound404} element={<Login />} /> */}
          </>
        )}
      </Routes>
    </Router>
  );
}
