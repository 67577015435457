import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { useSharedValue } from "react-native-reanimated";
import { useSearchParams } from "react-router-dom";

import { updateAnswerOptionQuestion } from "../../../shared/components/Sliders/utils";
import { fontColorLight, yellow } from "../../../theme/colors";
import MappableCard from "../../components/animated/MappableCard";
import CustomContextMenu from "../../components/buttons/CustomContextMenuButton";
import EditableMappingQuestion from "../../components/cards/EditableMappingQuestion";
import PageLayout from "../../components/layouts/PageLayout";
import AddContingentQuestionModal from "../../components/modals/AddContingentQuestionModal";
import DeleteQuestionModal from "../../components/modals/DeleteQuestionModal";
import QAUserNotificationsModal from "../../components/modals/QAUserNotificationsModal";
import SelectNewFormQuestion from "../../components/modals/SelectNewFormQuestion";
import LSSpinner from "../../components/spinner/LSSpinner";
import LSText, { textVariant } from "../../components/text/LSText";
import useForm from "../../hooks/useForm";
import useUsers from "../../hooks/useUsers";
import {
  customAnswerContextMenu,
  customQuestionContextMenu,
  pageAppTypes,
} from "../constants";
import {
  setFormStructure,
  checkPathsEqual,
  getTargetPath,
  recursiveOrderQuestions,
} from "../utils";

const pageTitle = "Question Mapping";

export default function IncidentsReportsMapping() {
  const [searchParams] = useSearchParams();
  const formID = searchParams.get("form");
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const { form, loadingForm, setForm } = useForm(formID);
  const scrollY = useSharedValue(0);
  const [dragItemIndex, setDragItemIndex] = useState();
  const [dragOverItemIndex, setDragOverItemIndex] = useState();
  const [trashModalVisible, setTrashModalVisible] = useState(false);
  const [userNotificationsModalVisible, setUserNotificationsModalVisible] =
    useState(false);
  const [editQuestion, setEditQuestion] = useState(false);
  const [addContingentModalVisible, setAddContingentModalVisible] =
    useState(false);
  const [contextMenuQuestionAnswer, setContextMenuQuestionAnswer] =
    useState(false);
  const [visible, setVisible] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(null);
  const { users } = useUsers();

  const handleSwapQuestions = () => {
    const questionsArr = [...form.questions];
    const dragQuestion = questionsArr.splice(dragItemIndex.questionIdx, 1)[0];
    questionsArr.splice(dragOverItemIndex.questionIdx, 0, dragQuestion);
    setForm((prevState) => ({
      ...prevState,
      questions: recursiveOrderQuestions(questionsArr),
    }));
    return dragQuestion;
  };

  const handleCloseContextMenu = () => {
    setShowContextMenu(null);
  };

  const handleContextMenuClick = (e, questionAnswer) => {
    e.preventDefault();
    console.log({ e, questionAnswer });
    setContextMenuQuestionAnswer(questionAnswer);

    setShowContextMenu({
      cardX: e.nativeEvent.clientX,
      cardY: e.nativeEvent.clientY,
    });
  };

  const handleDupQuestion = (dupObject) => {
    const { uuid, idxObject } = dupObject;
    const targetAddPath = getTargetPath(idxObject);
    setShowContextMenu(null);
    const questionsArrCopy = [...form.questions];

    const q = form.questionsList.find((_q) => _q.uuid === uuid);
    console.log({ q });
    recursiveAddSubQuestion(questionsArrCopy, targetAddPath, q);
    setForm((prevState) => ({
      ...prevState,
      questions: questionsArrCopy,
    }));
  };

  const recursiveAddSubQuestion = (
    questionArr,
    targetPath,
    addQuestion,
    _newAOUUID
  ) => {
    if (targetPath.length < 2) {
      const draggedQuestion = {
        ...addQuestion,
        answerOptionUUID: _newAOUUID,
      };
      if (questionArr.length === 0) {
        return {
          draggedQuestion: { ...draggedQuestion, order: 0 },
          questionArr: questionArr.push(addQuestion),
        };
      }
      const spliceIntoIdx = targetPath[0].questionIdx || targetPath[0].subIdx;

      questionArr.splice(spliceIntoIdx, 0, draggedQuestion);
      return {
        draggedQuestion: { ...draggedQuestion, order: spliceIntoIdx },
        questionArr,
      };
    }

    const topObject = targetPath[targetPath.length - 1];
    const _parentIdx =
      topObject.questionIdx !== undefined
        ? topObject.questionIdx
        : topObject.subIdx;
    const aoIdx = targetPath[targetPath.length - 2]?.answerIdx;
    const questionArrIdx = targetPath.length === 2 ? _parentIdx : _parentIdx;

    const qArr = questionArr[questionArrIdx].answerOptions[aoIdx].subQuestions;
    const newAOUUID = questionArr[_parentIdx].answerOptions[aoIdx].uuid;
    targetPath.pop();
    // console.log({ qArr, targetPath });
    return recursiveAddSubQuestion(qArr, targetPath, addQuestion, newAOUUID);
  };

  const recursiveRemoveQuestion = (questionsArr, targetPath, _oldAOUUID) => {
    if (targetPath.length < 2) {
      const targetIdx = targetPath[0].questionIdx || targetPath[0].subIdx;
      const draggedQuestion = questionsArr.splice(targetIdx, 1)[0];
      draggedQuestion.oldAnswerOptionUUID = _oldAOUUID;
      return { draggedQuestion, questionsArr };
    }

    const topObject = targetPath[targetPath.length - 1];

    const _parentIdx =
      topObject.questionIdx !== undefined
        ? topObject.questionIdx
        : topObject.subIdx;
    const aoIdx = targetPath[targetPath.length - 2]?.answerIdx;
    const qArr = questionsArr[_parentIdx].answerOptions[aoIdx].subQuestions;
    const oldAOUUID = questionsArr[_parentIdx].answerOptions[aoIdx].uuid;
    targetPath.pop();

    // console.log({ qArr, questionsArr, dragItemIndex, _parentIdx, aoIdx });
    return recursiveRemoveQuestion(qArr, targetPath, oldAOUUID);
  };

  const handleRemoveSubQuestion = () => {
    const targetAddPath = getTargetPath(dragOverItemIndex);
    const targetRemovePath = getTargetPath(dragItemIndex);
    if (checkPathsEqual(targetAddPath, targetRemovePath)) return false;

    const questionsArrCopy = [...form.questions];
    const { draggedQuestion } = recursiveRemoveQuestion(
      questionsArrCopy,
      targetRemovePath
    );

    const recursiveAdd = recursiveAddSubQuestion(
      questionsArrCopy,
      targetAddPath,
      draggedQuestion
    );
    console.log({ draggedQuestion, recursiveAdd });

    setForm((prevState) => ({
      ...prevState,
      questions: recursiveOrderQuestions(questionsArrCopy),
    }));
    return recursiveAdd;
  };

  const handleDrop = () => {
    console.log("handleDrop", { dragItemIndex, dragOverItemIndex });
    let draggedQuestion;
    let questionsArr;
    if (
      dragItemIndex.questionIdx !== undefined &&
      dragOverItemIndex.questionIdx !== undefined
    ) {
      draggedQuestion = handleSwapQuestions();
    } else {
      const recursiveAdd = handleRemoveSubQuestion();
      draggedQuestion = recursiveAdd.draggedQuestion;
      questionsArr = recursiveAdd.questionArr;
    }
    if (draggedQuestion)
      updateAnswerOptionQuestion(draggedQuestion, questionsArr);
  };

  const handleOnPress = (id, idx) => {
    setSelectedQuestion(form.questions[idx]);
  };

  const handleDeleteQuestion = async (idxObject) => {
    const targetAddPath = getTargetPath(idxObject);
    setShowContextMenu(null);
    const questionsArrCopy = [...form.questions];

    recursiveRemoveQuestion(questionsArrCopy, targetAddPath);
    setForm((prevState) => ({
      ...prevState,
      questions: recursiveOrderQuestions(questionsArrCopy),
    }));
  };

  const handleDuplicateQuestionClick = () => {
    setAddContingentModalVisible(true);
  };

  const handleAddNewNextQuestionClick = () => {
    setEditQuestion(false);
    setVisible(true);
  };

  const handleEditQuestionClick = () => {
    setEditQuestion(true);
    setVisible(true);
  };

  const handleAddNewQuestion = (newQuestion) => {
    const targetAddPath = getTargetPath(newQuestion.idxObject);
    if (targetAddPath.length > 1) {
      targetAddPath[0].subIdx += 1;
    } else {
      targetAddPath[0].questionIdx += 1;
    }
    setShowContextMenu(null);
    const questionsArrCopy = [...form.questions];

    recursiveAddSubQuestion(questionsArrCopy, targetAddPath, newQuestion);
    setForm((prevState) => ({
      ...prevState,
      questions: questionsArrCopy,
    }));
  };

  const handleEditQuestion = (newForm) => {
    console.log({ newForm });
    setFormStructure(newForm);
    setForm(newForm);
    setShowContextMenu(null);
  };

  const handleUpdateQAUserNotifications = (newForm) => {
    console.log({ selectedQuestion, newForm });
    setFormStructure(newForm);
    setForm(newForm);
    setShowContextMenu(null);
  };

  const handleShowTrashModal = () => {
    setTrashModalVisible(true);
  };

  const handleSetDragItemIdx = (idxObject) => {
    setDragItemIndex(idxObject);
  };

  const handleUserNotificationsClick = () => {
    setUserNotificationsModalVisible(true);
  };

  return (
    <>
      <PageLayout pageAppType={pageAppTypes.incidentReports}>
        <LSText
          variant={textVariant.h1}
          text={pageTitle}
          color={fontColorLight}
        />
        {loadingForm ? (
          <View style={styles.spinnerContainerStyles}>
            <LSSpinner />
          </View>
        ) : (
          <>
            <LSText
              variant={textVariant.h3}
              text={form.name}
              color={fontColorLight}
            />
            <View style={styles.bodyContainerStyles}>
              {form?.questions?.map((question, idx) =>
                selectedQuestion.uuid === question.uuid ? (
                  <EditableMappingQuestion
                    key={question.uuid}
                    setDragItemIndex={handleSetDragItemIdx}
                    setDragOverItemIndex={setDragOverItemIndex}
                    handleDrop={handleDrop}
                    _idx={idx}
                    question={question}
                    handleDeleteQuestion={() => setTrashModalVisible(true)}
                    setSelectedQuestion={setSelectedQuestion}
                    showEditQuestionModal={setVisible}
                    idxObject={{ questionIdx: idx }}
                    handleContextMenuClick={handleContextMenuClick}
                  />
                ) : (
                  <MappableCard
                    key={question.uuid}
                    handleOnPress={handleOnPress}
                    questionAnswer={question}
                    idx={idx}
                    scrollY={scrollY}
                    setDragItemIndex={handleSetDragItemIdx}
                    setDragOverItemIndex={setDragOverItemIndex}
                    handleDrop={handleDrop}
                    cardStyles={styles.mappableQuestionStyles}
                    idxObject={{ questionIdx: idx }}
                    handleContextMenuClick={handleContextMenuClick}
                  />
                )
              )}
            </View>
          </>
        )}
        <DeleteQuestionModal
          trashModalVisible={trashModalVisible}
          setTrashModalVisible={setTrashModalVisible}
          selectedQuestionAnswer={contextMenuQuestionAnswer}
          handleDeleteQuestion={handleDeleteQuestion}
        />
        <QAUserNotificationsModal
          visible={userNotificationsModalVisible}
          setVisible={setUserNotificationsModalVisible}
          users={users}
          questionAnswerOption={{
            ...contextMenuQuestionAnswer,
            formUUID: form.uuid,
          }}
          handleUpdateQAUserNotifications={handleUpdateQAUserNotifications}
        />
        <AddContingentQuestionModal
          addContingentModalVisible={addContingentModalVisible}
          setAddContingentModalVisible={setAddContingentModalVisible}
          selectedQuestionAnswer={contextMenuQuestionAnswer}
          questionsArr={form.questionsList || []}
          handleDupQuestion={handleDupQuestion}
        />
        <SelectNewFormQuestion
          form={form}
          formIdx={0}
          visible={visible}
          setVisible={setVisible}
          editQuestion={editQuestion}
          selectedQuestion={selectedQuestion}
          handleSetForm={handleAddNewQuestion}
          handleEditQuestion={handleEditQuestion}
          setSelectedQuestion={setSelectedQuestion}
          selectedQuestionAnswer={contextMenuQuestionAnswer}
          // eslint-disable-next-line no-unsafe-optional-chaining
          posX={showContextMenu?.cardX + 20}
          // eslint-disable-next-line no-unsafe-optional-chaining
          posY={showContextMenu?.cardY + 4}
        />
      </PageLayout>
      {showContextMenu && (
        <CustomContextMenu
          showContextMenu={showContextMenu}
          handleCloseContextMenu={handleCloseContextMenu}
          buttonObjectArr={
            contextMenuQuestionAnswer.typeCode
              ? customQuestionContextMenu
              : customAnswerContextMenu
          }
          handleDuplicateQuestionClick={handleDuplicateQuestionClick}
          handleDeleteQuestion={handleShowTrashModal}
          handleAddNewNextQuestionClick={handleAddNewNextQuestionClick}
          handleEditQuestionClick={handleEditQuestionClick}
          handleUserNotificationsClick={handleUserNotificationsClick}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  bodyContainerStyles: {
    marginTop: 32,
  },
  selectedQuestionContainerStyles: {
    borderColor: yellow[400],
    borderWidth: 2,
    borderRadius: 16,
    padding: 16,
    paddingTop: 8,
    paddingLeft: 8,
    marginBottom: 16,
  },
  spinnerContainerStyles: {
    height: "80vh",
    justifyContent: "center",
  },
  editQuestionTopContainer: {
    flexDirection: "row",
  },
  topContainerIconsContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 8,
    paddingBottom: 16,
  },
  mappableQuestionStyles: { padding: 8, marginBottom: 16 },
  mappableAnswerStyles: {
    padding: 8,
    marginLeft: 16,
    marginBottom: 16,
    flex: 1,
  },
  mappableSubQuestionStyles: {
    padding: 8,
    marginLeft: 32,
    marginBottom: 16,
    flex: 1,
  },
  buttonsContainer: {
    flexDirection: "row",
    width: "100%",
  },
});
