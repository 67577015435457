/* eslint-disable import/no-named-as-default */
import { FontAwesome } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";

import {
  apiGenerateNewForm,
  apiGetForms,
} from "../../../shared/hoc/pagesWeb/incidentReporter/utils";
import { fontColorLight } from "../../../theme/colors";
import LSButton, { buttonVariants } from "../../components/buttons/LSButton";
import Card, { cardVariant } from "../../components/cards/Card";
import FormCard from "../../components/cards/FormCard";
import LSInput, { inputVariants } from "../../components/formInputs/LSInput";
import PageLayout from "../../components/layouts/PageLayout";
import FormSettingsModalContent from "../../components/modals/FormSettingsModalContent";
import LSModal from "../../components/modals/LSModal";
import SelectNewFormQuestion from "../../components/modals/SelectNewFormQuestion";
import LSText, { textVariant } from "../../components/text/LSText";
import useUsers from "../../hooks/useUsers";
import { pageAppTypes } from "../constants";

const pageTitle = "Incident Reports";

export default function IncidentsForms() {
  const [visible, setVisible] = useState(false);
  const [forms, setForms] = useState([]);
  const [form, setForm] = useState({});
  const [newForm, setNewForm] = useState({ name: "" });
  const [formIdx, setFormIdx] = useState(0);
  const [addModalVisible, setAddModalVisible] = useState(0);
  const [position, setPosition] = useState({ posX: 0, posY: 0 });
  const { users } = useUsers();

  const getForms = async () => {
    const response = await apiGetForms();
    setForms(response);
    setForm(response[0]);
  };

  const handleOnPressAddQuestion = (event, _form, idx) => {
    setForm(_form);
    setFormIdx(idx);

    setPosition({
      posX: event.pageX - event.nativeEvent.layerX + 11,
      posY: event.pageY - event.nativeEvent.layerY - 4,
    });
    setVisible(true);
  };

  const handleOnPressPublish = (idx) => {
    const tempForms = [...forms];
    tempForms[idx].isPublished = !tempForms[idx].isPublished;
    setForms(tempForms);
  };

  const handleOnLockPublish = (idx) => {
    const tempForms = [...forms];
    tempForms[idx].isLocked = !tempForms[idx].isLocked;
    setForms(tempForms);
  };

  const handleUpdateFormSettings = (changedForm) => {
    const idx = forms.findIndex((_form) => _form.uuid === changedForm.uuid);
    const tempForms = [...forms];
    tempForms[idx].formAdmins = changedForm.formAdmins;
    tempForms[idx].accessType = changedForm.accessType;
    setForms(tempForms);
  };

  const handleAddNewForm = () => {
    setAddModalVisible(1);
  };

  const handleGenerateFormPress = async () => {
    if (!newForm.name) return;
    const response = await apiGenerateNewForm(newForm);
    if (response !== "asdlkj") {
      setForms((prevState) => [response, ...prevState]);
      setNewForm({ name: "" });
      setAddModalVisible(false);
    }
  };

  const handleSetForm = (response) => {
    setForms((prevState) => {
      const _forms = [...prevState];
      _forms[formIdx].questions.push(response);
      return forms;
    });
  };

  const handleOnEditPress = (_form) => {
    console.log("Editing", { _form });
    setForm(_form);
    setAddModalVisible(2);
  };

  const getModalContent = () => {
    if (addModalVisible === 1) {
      return (
        <>
          <View style={{ width: "100%", marginBottom: 32 }}>
            <LSText
              text="Add New Form"
              variant={textVariant.h2}
              customStyles={{ marginBottom: 16 }}
            />
            <LSInput
              variant={inputVariants.primary}
              setter={setNewForm}
              object={newForm}
              name="name"
              customStyles={{ margin: 0 }}
            />
          </View>
          <View style={styles.buttonsContainer}>
            <LSButton
              onPress={() => setAddModalVisible(false)}
              text="Cancel"
              variant={buttonVariants.secondary}
              customStyles={{ marginRight: 16, flex: 1 }}
            />
            <LSButton
              onPress={handleGenerateFormPress}
              variant={buttonVariants.primary}
              text="Save"
              customStyles={{ flex: 1 }}
            />
          </View>
        </>
      );
    }
    if (addModalVisible === 2) {
      return (
        <FormSettingsModalContent
          form={form}
          users={users}
          closeModal={() => setAddModalVisible(0)}
          handleUpdateFormSettings={handleUpdateFormSettings}
        />
      );
    }
    return "";
  };

  useEffect(() => {
    getForms();
  }, []);

  return (
    <PageLayout pageAppType={pageAppTypes.incidentReports}>
      <LSText variant={textVariant.h1} text={pageTitle} color="white" />
      <View style={styles.headerContainerStyles}>
        <LSText
          variant={textVariant.h3}
          text="Forms"
          color="white"
          customStyles={{ marginBottom: 0, fontWeight: "bold" }}
        />
        <Pressable onPress={handleAddNewForm}>
          <FontAwesome name="plus" size={25} color={fontColorLight} />
        </Pressable>
      </View>
      <View style={styles.formsContainerStyles}>
        {forms?.map((_form, idx) => (
          <FormCard
            key={_form.uuid}
            name={_form.name}
            form={_form}
            questionCount={_form.questionCount}
            handleOnPressAddQuestion={(e) =>
              handleOnPressAddQuestion(e, _form, idx)
            }
            handleOnPressPublish={() => handleOnPressPublish(idx)}
            handleOnLockPublish={() => handleOnLockPublish(idx)}
            handleOnEditPress={handleOnEditPress}
          />
        ))}
      </View>
      <SelectNewFormQuestion
        form={form}
        formIdx={formIdx}
        handleSetForm={handleSetForm}
        visible={visible}
        setVisible={setVisible}
        posX={position.posX}
        posY={position.posY}
      />
      <LSModal
        visible={Boolean(addModalVisible)}
        setVisible={setAddModalVisible}
      >
        <Card
          variant={cardVariant.primary}
          customStyles={{ width: 310, maxWidth: "100%" }}
        >
          {getModalContent()}
        </Card>
      </LSModal>
    </PageLayout>
  );
}

const styles = StyleSheet.create({
  formsContainerStyles: {
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 50,
  },
  headerContainerStyles: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  selectNewQuestionTypeModal: {
    backgroundColor: "black",
    opacity: 0.5,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  selectNewQuestionTypePressableStyles: {
    height: "100%",
    width: "100%",
  },
  buttonsContainer: {
    flexDirection: "row",
    width: "100%",
  },
});
