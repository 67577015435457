import {
  FontAwesome,
  FontAwesome5,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import PropTypes from "prop-types";
import React from "react";
import { Pressable, StyleSheet, View } from "react-native";

import { fontColorLight, yellow } from "../../../theme/colors";
import { fontFamilyTypes } from "../../pages/constants";
import LSText, { textVariant } from "../text/LSText";

const selectedYellow = yellow[400];

export default function SideNavButton({
  iconName,
  name,
  urlRoute,
  iconFamily,
}) {
  const selected = urlRoute === window.location.pathname;
  const handlePress = () => {
    window.location.href = urlRoute;
  };
  return (
    <View style={style.buttonContainer}>
      <Pressable style={style.pressableStyles} onPress={handlePress}>
        <View style={{ width: 30 }}>
          {(!iconFamily || iconFamily === fontFamilyTypes.fontAwesome) && (
            <FontAwesome
              name={iconName}
              size={25}
              color={selected ? selectedYellow : fontColorLight}
            />
          )}
          {iconFamily === fontFamilyTypes.materialCommunityIcons && (
            <MaterialCommunityIcons
              name={iconName}
              size={25}
              color={selected ? selectedYellow : fontColorLight}
            />
          )}
          {iconFamily === fontFamilyTypes.fontAwesome5 && (
            <FontAwesome5
              name={iconName}
              size={20}
              color={selected ? selectedYellow : fontColorLight}
            />
          )}
        </View>
        <LSText
          text={name}
          color={selected ? selectedYellow : fontColorLight}
          variant={textVariant.button}
          customStyles={style.customTextStyles}
        />
      </Pressable>
    </View>
  );
}

const style = StyleSheet.create({
  buttonContainer: {
    borderBottomWidth: 1,
    borderBottomColor: fontColorLight,
  },
  pressableStyles: {
    paddingVertical: 12,
    width: "100%",
    height: "100%",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 12,
  },
  customTextStyles: { marginLeft: 12, marginTop: 0, fontSize: 16 },
});

SideNavButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  urlRoute: PropTypes.string.isRequired,
  iconFamily: PropTypes.string,
};

SideNavButton.defaultProps = {
  iconFamily: null,
};
