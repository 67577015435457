import { FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import Notifications from "@mui/icons-material/NotificationsNone";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";

import Card, { cardVariant } from "./Card";
import {
  fontColorDark,
  fontColorLight,
  green,
  yellow,
} from "../../../theme/colors";
import MappableCard from "../animated/MappableCard";
import LSText, { textVariant } from "../text/LSText";

const expandIcon = "expand-arrows-alt";
const compressIcon = "compress-arrows-alt";

export default function EditableMappingQuestion({
  setDragItemIndex,
  setDragOverItemIndex,
  handleDrop,
  _idx,
  question,
  handleDeleteQuestion,
  setSelectedQuestion,
  showEditQuestionModal,
  idxObject,
  handleContextMenuClick,
  setParentCollapse,
  setParentSelectedSubQuestion,
  setParentSelectedAnswerOption,
}) {
  console.log({ question });
  const [selectedSubQuestion, setSelectedSubQuestion] = useState({});
  const [selectedAnswerOption, setSelectedAnswerOption] = useState({});
  const [collapse, setCollapse] = useState(false);
  const { text, typeCode, answerOptions } = question;

  const handleSubQuestionPressed = (subQuestionUUID, option) => {
    setSelectedSubQuestion({ uuid: subQuestionUUID });
    setSelectedAnswerOption(option);
    setCollapse(true);
  };

  const setEditableQuestionCheckPressed = () => {
    if (setParentCollapse) {
      console.log("Hitting", question);
      setParentSelectedSubQuestion({});
      setParentSelectedAnswerOption({});
      setParentCollapse(false);
    } else {
      setSelectedQuestion({});
    }
  };

  const handleCollapsePress = () => {
    setCollapse((prevState) => !prevState);
  };

  const generateSubQuestions = () => {
    const options = answerOptions?.map((option, idx) => (
      <>
        <MappableCard
          handleOnPress={() => console.log("Answer Pressed", option.uuid)}
          questionAnswer={option}
          key={option.uuid}
          idx={idx}
          setDragItemIndex={setDragItemIndex}
          setDragOverItemIndex={setDragOverItemIndex}
          handleDrop={handleDrop}
          idxObject={{ parentObject: idxObject, answerIdx: idx }}
          cardStyles={styles.mappableAnswerStyles}
          collapse={false}
          handleContextMenuClick={handleContextMenuClick}
        />
        {option?.subQuestions?.map((subQuestion, i) => {
          const _idxObject = {
            parentObject: idxObject,
            answerIdx: idx,
            subIdx: i,
          };
          const objectIds = {
            answerID: option.uuid,
            questionID: subQuestion.uuid,
          };
          const key = `${subQuestion.uuid}-${option.uuid}`;
          return selectedSubQuestion.uuid === subQuestion.uuid &&
            option.uuid === selectedAnswerOption.uuid ? (
            <EditableMappingQuestion
              key={key}
              setDragItemIndex={setDragItemIndex}
              setDragOverItemIndex={setDragOverItemIndex}
              handleDrop={handleDrop}
              _idx={i}
              question={subQuestion}
              handleDeleteQuestion={handleDeleteQuestion}
              setSelectedQuestion={setSelectedQuestion}
              showEditQuestionModal={showEditQuestionModal}
              idxObject={_idxObject}
              handleContextMenuClick={handleContextMenuClick}
              setParentCollapse={setCollapse}
              setParentSelectedAnswerOption={setSelectedAnswerOption}
              setParentSelectedSubQuestion={setSelectedSubQuestion}
            />
          ) : (
            <MappableCard
              key={key}
              handleOnPress={() =>
                handleSubQuestionPressed(subQuestion.uuid, option)
              }
              questionAnswer={subQuestion}
              objectIds={objectIds}
              idx={i}
              setDragItemIndex={setDragItemIndex}
              setDragOverItemIndex={setDragOverItemIndex}
              handleDrop={handleDrop}
              idxObject={_idxObject}
              cardStyles={styles.mappableSubQuestionStyles}
              collapse={collapse}
              handleContextMenuClick={handleContextMenuClick}
            />
          );
        })}
      </>
    ));
    return options;
  };

  return (
    <View
      style={styles.selectedQuestionContainerStyles}
      key={`parent-${question.uuid}`}
    >
      <View style={styles.editQuestionTopContainer}>
        <div
          onContextMenu={(e) =>
            handleContextMenuClick(e, { ...question, idxObject })
          }
          style={{ width: "100%" }}
        >
          <Card
            variant={cardVariant.primary}
            customStyles={{
              padding: 8,
              marginBottom: 16,
              flex: 1,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                flex: 1,
                width: "100%",
              }}
            >
              <LSText
                variant={textVariant.h6}
                text={`Q${_idx + 1} | ${text}`}
                color={fontColorDark}
              />
              {question.qaUserNotifications?.length > 0 ? (
                <Notifications
                  sx={{
                    color: "#ba000d",
                    marginTop: "-4px",
                    marginBottom: -1,
                  }}
                />
              ) : (
                ""
              )}
            </View>
          </Card>
        </div>
        <View style={styles.topContainerIconsContainer}>
          <Pressable
            key="expand"
            onPress={handleCollapsePress}
            style={{ marginRight: 8 }}
          >
            <FontAwesome5
              name={collapse ? expandIcon : compressIcon}
              size={20}
              color={fontColorLight}
            />
          </Pressable>
        </View>
      </View>
      {(typeCode === 3 || typeCode === 1 || typeCode === 7) && (
        <>
          <LSText
            variant={textVariant.h6}
            text="Drag and drop for answer contingent questions"
            color={fontColorLight}
            customStyles={{ marginTop: -12, marginBottom: 12 }}
          />
          {generateSubQuestions(answerOptions)}
        </>
      )}
      <View style={styles.finishContainerStyles}>
        <Pressable key="deselect" onPress={setEditableQuestionCheckPressed}>
          <FontAwesome name="check-circle" size={24} color={green[400]} />
        </Pressable>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bodyContainerStyles: {
    marginTop: 32,
  },
  selectedQuestionContainerStyles: {
    borderColor: yellow[400],
    borderWidth: 2,
    borderRadius: 16,
    padding: 16,
    paddingTop: 8,
    paddingLeft: 8,
    marginBottom: 16,
  },
  spinnerContainerStyles: {
    height: "80vh",
    justifyContent: "center",
  },
  editQuestionTopContainer: {
    flexDirection: "row",
  },
  topContainerIconsContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 8,
    paddingBottom: 16,
  },
  mappableQuestionStyles: { padding: 8, marginBottom: 16 },
  mappableAnswerStyles: {
    padding: 8,
    marginLeft: 4,
    marginBottom: 16,
    flex: 1,
  },
  mappableSubQuestionStyles: {
    padding: 8,
    marginLeft: 32,
    marginBottom: 16,
    flex: 1,
  },
  buttonsContainer: {
    flexDirection: "row",
    width: "100%",
  },
  finishContainerStyles: {
    flex: 1,
    alignItems: "end",
  },
});

EditableMappingQuestion.propTypes = {
  setDragItemIndex: PropTypes.func.isRequired,
  setDragOverItemIndex: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  _idx: PropTypes.number.isRequired,
  question: PropTypes.object.isRequired,
  handleDeleteQuestion: PropTypes.func.isRequired,
  setSelectedQuestion: PropTypes.func.isRequired,
  handleContextMenuClick: PropTypes.func.isRequired,
  showEditQuestionModal: PropTypes.func.isRequired,
  idxObject: PropTypes.object.isRequired,
  setParentCollapse: PropTypes.func,
  setParentSelectedSubQuestion: PropTypes.func,
  setParentSelectedAnswerOption: PropTypes.func,
};

EditableMappingQuestion.defaultProps = {
  setParentCollapse: null,
  setParentSelectedSubQuestion: () => {},
  setParentSelectedAnswerOption: () => {},
};
