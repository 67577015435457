import { useState, useEffect } from "react";

import { GET_USERS } from "../../shared/apiUrls";
import { apiGetAuth } from "../../shared/hoc/pagesWeb/incidentReporter/utils";

function useUsers() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    console.log({ users });
  }, [users]);

  const getUsers = async () => {
    const results = await apiGetAuth(GET_USERS);
    setUsers(results);
    setLoading(false);
  };

  return {
    loading,
    users,
  };
}

export default useUsers;
