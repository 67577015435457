import React from "react";
import { StyleSheet, View } from "react-native";

import { homeApps } from "./constants";
import HomeAppCard from "../components/cards/HomeAppCard";
import PageLayout from "../components/layouts/PageLayout";
import LSText, { textVariant } from "../components/text/LSText";

const pageTitle = "Home";

export default function Home() {
  return (
    <PageLayout>
      <LSText variant={textVariant.h1} text={pageTitle} color="white" />
      <View style={styles.bodyContainerStyles}>
        {homeApps?.map((app) => (
          <HomeAppCard key={app.id} app={app} />
        ))}
      </View>
    </PageLayout>
  );
}

const styles = StyleSheet.create({
  bodyContainerStyles: {
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
