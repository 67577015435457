import PropTypes from "prop-types";
import React, { useMemo } from "react";
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  View,
  useWindowDimensions,
} from "react-native";

import { blue } from "../../../theme/colors";
import { isIOS } from "../../../utils/environments";
import {
  pageBreakPointValues,
  pageTypeComponents,
} from "../../pages/constants";
import BottomNavButton from "../buttons/BottomNavButton";
import SideNavButton from "../buttons/SideNavButton";

export default function PageLayout({ children, center, pageAppType }) {
  const { height, width } = useWindowDimensions();
  const minHeight = isIOS ? "100%" : height;

  const showFooterNav = useMemo(() => width < pageBreakPointValues.lg, [width]);

  return (
    <SafeAreaView style={styles.safeAreaViewStyles}>
      <View style={{ flexDirection: "row" }}>
        {!showFooterNav && pageAppType ? (
          <>
            <View
              style={{
                ...styles.sideMenuStyles,
                width: width > pageBreakPointValues.xl ? 250 : 200,
                minHeight: height,
              }}
            >
              <View style={{ height: 100, width: "100%" }} />
              {pageTypeComponents[pageAppType].footerButtons.map((button) => (
                <SideNavButton
                  key={button.id}
                  name={button.name}
                  iconName={button.iconName}
                  urlRoute={button.urlRoute}
                  iconFamily={button.iconFamily}
                />
              ))}
            </View>
            <ScrollView style={styles.pageScrollViewStyles}>
              <View
                style={{
                  ...(center
                    ? { ...styles.centerPageStyles, minHeight }
                    : styles.pageStyles),
                  ...(showFooterNav && { width: width - 200 }),
                }}
              >
                {children}
              </View>
            </ScrollView>
          </>
        ) : (
          <ScrollView style={styles.pageScrollViewStyles}>
            <View
              style={{
                ...(center
                  ? { ...styles.centerPageStyles, minHeight }
                  : styles.pageStyles),
              }}
            >
              {children}
            </View>
          </ScrollView>
        )}
      </View>
      {pageAppType && showFooterNav && (
        <View style={styles.footerStyles}>
          {pageTypeComponents[pageAppType].footerButtons.map((button) => (
            <BottomNavButton
              key={button.id}
              name={button.name}
              iconName={button.iconName}
              urlRoute={button.urlRoute}
              iconFamily={button.iconFamily}
            />
          ))}
        </View>
      )}
    </SafeAreaView>
  );
}

const menuBackgroundColor = blue[700];

const styles = StyleSheet.create({
  safeAreaViewStyles: {
    backgroundColor: blue[500],
    paddingTop: StatusBar.currentHeight,
    flex: 1,
    justifyContent: "center",
    position: "relative",
  },
  pageScrollViewStyles: {
    position: "relative",
    height: "100vh",
    paddingBottom: 100,
  },
  centerPageStyles: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
  },
  pageStyles: {
    alignItems: "center",
    minHeight: 100,
    padding: 16,
  },
  footerStyles: {
    backgroundColor: menuBackgroundColor,
    flexDirection: "row",
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: 80,
  },
  sideMenuStyles: {
    height: "100%",
    backgroundColor: menuBackgroundColor,
  },
});

PageLayout.propTypes = {
  children: PropTypes.any.isRequired,
  center: PropTypes.bool,
  pageAppType: PropTypes.string,
};

PageLayout.defaultProps = {
  center: false,
  pageAppType: undefined,
};
