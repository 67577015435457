import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Pressable, StyleSheet, View, useWindowDimensions } from "react-native";
import { useSearchParams } from "react-router-dom";

import pageRoutes from "../../../shared/hoc/constants/pageRoutes";
import {
  questionTypeCodes,
  questionTypeHeights,
  questionTypeOptionsShort,
} from "../../../shared/hoc/pagesWeb/incidentReporter/constants";
import { apiGetForm } from "../../../shared/hoc/pagesWeb/incidentReporter/utils";
import { fontColorDark, fontColorLight, yellow } from "../../../theme/colors";
import LSButton, { buttonVariants } from "../../components/buttons/LSButton";
import Card, { cardVariant } from "../../components/cards/Card";
import LSDropdownPicker from "../../components/formInputs/LSDropdownPicker";
import LSInput, { inputVariants } from "../../components/formInputs/LSInput";
import LSSpinner from "../../components/spinner/LSSpinner";
import LSText, { textVariant } from "../../components/text/LSText";
import { pageAppTypes } from "../constants";
import "react-datepicker/dist/react-datepicker.css";
import ProgressBar from "../../components/progressIndicators/ProgressBar";
import PageLayout from "../../components/layouts/PageLayout";
import LSDropdownMultiplePicker from "../../components/formInputs/LSDropdownMultiplePicker";
import { getPageQuestionsArrs } from "../utils";

const pageTitle = "Form: Preview";

export default function IncidentsFormPreview() {
  const [searchParams] = useSearchParams();
  const formID = searchParams.get("form");
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState({});
  const [pagesArr, setPagesArr] = useState([]);
  const { height } = useWindowDimensions();
  const [pageIdx, setPageIdx] = useState(0);
  const [selectDate, setSelectDate] = useState(new Date());

  const endReached = pageIdx === pagesArr.length - 1;
  const startReached = pageIdx === 0;

  const getForm = async () => {
    setLoading(true);
    const response = await apiGetForm(formID);
    setForm(response);
    const pages = getPageQuestionsArrs(response.questions, height);
    setPagesArr(pages);
    setLoading(false);
  };

  const handlePressNext = () => {
    if (!endReached) {
      setPageIdx(pageIdx + 1);
    }
  };

  const handlePressBack = () => {
    if (!startReached) {
      setPageIdx(pageIdx - 1);
    }
  };

  const displayQuestionReponse = ({
    typeCode,
    answerOptions,
    multipleAnswers,
  }) => {
    if (typeCode === questionTypeCodes.dropdown) {
      const mappedQuestionItems = answerOptions?.map((option) => ({
        key: option.uuid,
        value: option.text,
      }));
      return <LSDropdownPicker _items={mappedQuestionItems} />;
    }
    if (typeCode === questionTypeCodes.multiple && !multipleAnswers) {
      const mappedQuestionItems = answerOptions?.map((option) => ({
        key: option.uuid,
        value: option.text,
      }));
      return (
        <View
          style={{
            marginBottom:
              questionTypeHeights[questionTypeOptionsShort[typeCode]]
                .baseHeight,
          }}
        >
          <LSDropdownMultiplePicker _items={mappedQuestionItems} multiple />
        </View>
      );
    }
    if (typeCode === questionTypeCodes.multiple && multipleAnswers) {
      const mappedQuestionItems = answerOptions?.map((option) => ({
        key: option.uuid,
        value: option.text,
      }));
      return (
        <View
          style={{
            marginBottom:
              questionTypeHeights[questionTypeOptionsShort[typeCode]]
                .baseHeight,
          }}
        >
          <LSDropdownPicker _items={mappedQuestionItems} />
        </View>
      );
    }
    if (typeCode === questionTypeCodes.calendar) {
      return (
        <View marginBottom="250px">
          <ReactDatePicker
            selected={selectDate}
            onChange={(date) => setSelectDate(date)}
          />
        </View>
      );
    }
    if (typeCode === questionTypeCodes.paragraph) {
      return (
        <View>
          <LSInput
            multiline
            variant={inputVariants.primary}
            placeholder=""
            name="input"
            object={{ input: "" }}
            errorObj={{ input: "" }}
            setter={() => {}}
          />
        </View>
      );
    }
    if (typeCode === questionTypeCodes.text) {
      return (
        <View>
          <LSInput
            variant={inputVariants.primary}
            placeholder=""
            name="input"
            object={{ input: "" }}
            errorObj={{ input: "" }}
            setter={() => {}}
          />
        </View>
      );
    }
    return "";
  };

  useEffect(() => {
    getForm();
  }, []);

  return (
    <PageLayout pageAppType={pageAppTypes.incidentReports}>
      <LSText
        variant={textVariant.h1}
        text={pageTitle}
        color={fontColorLight}
      />
      {loading ? (
        <View style={styles.spinnerContainerStyles}>
          <LSSpinner />
        </View>
      ) : (
        <View
          style={{
            minHeight: height - 200,
            width: "100%",
            alignItems: "center",
          }}
        >
          <Card
            variant={cardVariant.primary}
            customStyles={{
              width: "100%",
              height: "100%",
              maxWidth: "500px",
              justifyContent: "start",
            }}
          >
            <View
              style={{
                width: "100%",
                flex: 1,
                justifyContent: "space-between",
              }}
            >
              <View>
                <LSText
                  variant={textVariant.h3}
                  text={form.name}
                  color={fontColorDark}
                />
                {pagesArr[pageIdx]?.map((question) => (
                  <View
                    key={question.uuid}
                    style={{
                      width: "100%",
                      marginTop: 16,
                    }}
                  >
                    <LSText
                      variant={textVariant.h5}
                      text={question.text}
                      color={fontColorDark}
                      customStyles={{ marginBottom: 8 }}
                    />
                    {displayQuestionReponse(question)}
                  </View>
                ))}
              </View>
              <View>
                <ProgressBar count={pageIdx} total={pagesArr.length} />
                <View style={styles.buttonsContainer}>
                  <LSButton
                    onPress={handlePressBack}
                    text="Back"
                    disabled={startReached}
                    variant={buttonVariants.secondary}
                    customStyles={{ marginRight: 16, flex: 1 }}
                  />
                  <LSButton
                    onPress={handlePressNext}
                    variant={buttonVariants.primary}
                    text={endReached ? "Submit" : "Next"}
                    customStyles={{ flex: 1 }}
                  />
                </View>
              </View>
            </View>
          </Card>
          <View
            style={{
              width: "100%",
              maxWidth: "500px",
              alignItems: "flex-end",
            }}
          >
            <Pressable
              onPress={() => {
                window.open(pageRoutes.incidentsForms, "_self");
              }}
            >
              <LSText
                variant={textVariant.h3}
                text="End Preview"
                color={fontColorLight}
                customStyles={{ marginTop: 16, textAlign: "flex-end" }}
              />
            </Pressable>
          </View>
        </View>
      )}
    </PageLayout>
  );
}

const styles = StyleSheet.create({
  bodyContainerStyles: {
    marginTop: 0,
    // width: 500,
  },
  selectedQuestionContainerStyles: {
    borderColor: yellow[400],
    borderWidth: 2,
    borderRadius: 16,
    padding: 16,
    paddingTop: 8,
    paddingLeft: 8,
    marginBottom: 16,
  },
  spinnerContainerStyles: {
    height: "80vh",
    justifyContent: "center",
  },
  editQuestionTopContainer: {
    flexDirection: "row",
  },
  questionContainerStyles: {
    width: "100%",
    marginTop: 16,
  },
  mappableQuestionStyles: { padding: 8, marginBottom: 16 },
  mappableAnswerStyles: {
    padding: 8,
    marginLeft: 16,
    marginBottom: 16,
    flex: 1,
  },
  mappableSubQuestionStyles: {
    padding: 8,
    marginLeft: 32,
    marginBottom: 16,
    flex: 1,
  },
  buttonsContainer: {
    flexDirection: "row",
    width: "100%",
  },
});
