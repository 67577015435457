import { NavigationContainer } from "@react-navigation/native";
import { useFonts } from "expo-font";
import React from "react";
import { AppState } from "react-native";

import RobotoSerif from "./assets/fonts/RobotoSerif.ttf";
import { AuthProvider } from "./context/AuthContext";
import MainIOSNavigator from "./navigators/MainIOSNavigator";
import MainWebNavigator from "./navigators/MainWebNavigator";
import { isWeb } from "./utils/environments";

function App() {
  const [fontsLoaded] = useFonts({
    "Roboto-Serif": RobotoSerif,
  });

  if (!fontsLoaded) {
    return null;
  }

  if (AppState.currentState !== "active") {
    return null;
  }

  return (
    <AuthProvider>
      {isWeb ? (
        <MainWebNavigator />
      ) : (
        <NavigationContainer>
          <MainIOSNavigator />
        </NavigationContainer>
      )}
    </AuthProvider>
  );
}

export default App;
