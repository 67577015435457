import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

import LSModal from "./LSModal";
import { GET_LOCATIONS } from "../../../shared/apiUrls";
import { apiGetAuth } from "../../../shared/hoc/pagesWeb/incidentReporter/utils";
import { fontColorLight } from "../../../theme/colors";
import { validateEmail } from "../../pages/utils";
import LSButton, { buttonVariants } from "../buttons/LSButton";
import Card, { cardVariant } from "../cards/Card";
import LSDropdownMultiplePicker from "../formInputs/LSDropdownMultiplePicker";
import LSInput, { inputVariants } from "../formInputs/LSInput";
import LSText, { textVariant } from "../text/LSText";

const newUserObject = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  position: "",
  role: "",
};

const userErrorsObject = {
  firstName: false,
  lastName: false,
  email: false,
  phone: false,
  position: false,
  role: false,
};

export default function AddUserModal({
  setUsers,
  addModalVisible,
  setAddModalVisible,
}) {
  const [addUserStep, setAddUserStep] = useState({ parentStep: 0, subStep: 0 });
  const [newUser, setNewUser] = useState(newUserObject);
  const [userErrors, setUserErrors] = useState(userErrorsObject);
  const [locations, setLocations] = useState([]);
  const [answer, setAnswer] = useState({ locationIDs: [] });
  const [answerError, setAnswerError] = useState(false);

  const handleAddUserNextStep = () => {
    setAddUserStep((prevState) => ({
      ...prevState,
      parentStep: prevState.parentStep + 1,
    }));
  };

  const getLocations = async () => {
    const results = await apiGetAuth(GET_LOCATIONS);
    setLocations(results);
  };

  useEffect(() => {
    getLocations();
  }, []);

  const handleAddUserPress = async () => {
    console.log({ newUser });
    // const errors = validateLocationLocation();
    // console.log({ errors });

    // if (errors) return;

    // const response = await apiPostAuth(ADD_COMPANY_USER, newUser);
    // console.log({ response });
    // if (response.firstName) {
    //   setUsers((prevState) => [response, ...prevState]);
    //   setNewUser(newUserObject);
    //   setAddModalVisible(false);
    // }
  };

  const validateLocationLocation = () => {
    let errors = false;

    const keys = Object.keys(newUser);
    keys.forEach((key) => {
      if (!newUser[key]) {
        setUserErrors((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        errors = true;
      } else {
        setUserErrors((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    });
    const validEmail = validateEmail(newUser.email);
    if (!validEmail) {
      setUserErrors((prevState) => ({
        ...prevState,
        email: true,
      }));
      errors = true;
    } else {
      setUserErrors((prevState) => ({
        ...prevState,
        email: false,
      }));
    }
    console.log({ validEmail });

    return errors;
  };

  const getAddUserFormContent = () => {
    console.log("Getting");
    if (addUserStep.parentStep === 0) {
      if (addUserStep.subStep === 0) {
        return (
          <>
            <LSInput
              variant={inputVariants.primary}
              setter={setNewUser}
              object={newUser}
              name="firstName"
              placeholder="First Name"
              errorObj={userErrors}
              customStyles={styles.inputStyles}
            />
            <LSInput
              variant={inputVariants.primary}
              setter={setNewUser}
              object={newUser}
              name="lastName"
              placeholder="Last Name"
              errorObj={userErrors}
              customStyles={styles.inputStyles}
            />
            <LSInput
              variant={inputVariants.primary}
              setter={setNewUser}
              object={newUser}
              name="email"
              placeholder="Email"
              errorObj={userErrors}
              customStyles={styles.inputStyles}
            />
            <LSInput
              variant={inputVariants.primary}
              setter={setNewUser}
              object={newUser}
              name="phone"
              placeholder="Phone"
              errorObj={userErrors}
              customStyles={styles.inputStyles}
            />
            <LSInput
              variant={inputVariants.primary}
              setter={setNewUser}
              object={newUser}
              name="position"
              placeholder="Position"
              errorObj={userErrors}
              customStyles={styles.inputStyles}
            />
            <LSInput
              variant={inputVariants.primary}
              setter={setNewUser}
              object={newUser}
              name="role"
              placeholder="Role"
              errorObj={userErrors}
              customStyles={styles.inputStyles}
            />
          </>
        );
      }
    }
    if (addUserStep.parentStep === 1) {
      const mappedQuestionItems = locations?.map((option) => ({
        key: option.uuid,
        value: option.name,
      }));

      return (
        <LSDropdownMultiplePicker
          _items={mappedQuestionItems}
          answer={answer}
          name="locationIDs"
          setValue={setAnswer}
          answerError={answerError}
          allOption
        />
      );
    }
  };
  return (
    <LSModal visible={addModalVisible} setVisible={setAddModalVisible}>
      <Card
        variant={cardVariant.primary}
        customStyles={{ width: 310, maxWidth: "100%" }}
      >
        <View style={{ width: "100%", marginBottom: 32 }}>
          <LSText
            text="Add New User"
            variant={textVariant.h2}
            customStyles={{ marginBottom: 16, textAlign: "center" }}
          />

          {getAddUserFormContent()}
        </View>
        <View style={styles.buttonsContainer}>
          <LSButton
            onPress={() => setAddModalVisible(false)}
            text="Back"
            variant={buttonVariants.secondary}
            customStyles={{ marginRight: 16, flex: 1 }}
          />
          <LSButton
            onPress={true ? handleAddUserNextStep : handleAddUserPress}
            variant={buttonVariants.primary}
            text="Next"
            customStyles={{ flex: 1 }}
          />
        </View>
      </Card>
    </LSModal>
  );
}

const styles = StyleSheet.create({
  spinnerContainerStyles: {
    height: "80vh",
    justifyContent: "center",
  },
  headerContainerStyles: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  buttonsContainer: {
    flexDirection: "row",
    width: "100%",
  },
  inputStyles: { margin: 0, marginBottom: 8 },
  submissionContainer: {
    borderBottomColor: fontColorLight,
    borderBottomWidth: 1,
    flexDirection: "row",
    paddingTop: 16,
    paddingBottom: 8,
  },
  submissionContainerLeft: {
    flex: 1,
  },
});

AddUserModal.propTypes = {
  addModalVisible: PropTypes.bool.isRequired,
  setAddModalVisible: PropTypes.func.isRequired,
  setUsers: PropTypes.func.isRequired,
};
