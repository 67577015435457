import {
  FontAwesome,
  FontAwesome5,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import PropTypes from "prop-types";
import React from "react";
import { Pressable, StyleSheet, View } from "react-native";

import { fontColorLight, yellow } from "../../../theme/colors";
import { fontFamilyTypes } from "../../pages/constants";
import LSText, { textVariant } from "../text/LSText";

const selectedYellow = yellow[400];

export default function BottomNavButton({
  iconName,
  name,
  urlRoute,
  iconFamily,
}) {
  const selected = urlRoute === window.location.pathname;
  const handlePress = () => {
    window.location.href = urlRoute;
  };
  return (
    <View style={style.buttonContainer}>
      <Pressable style={style.pressableStyles} onPress={handlePress}>
        {(!iconFamily || iconFamily === fontFamilyTypes.fontAwesome) && (
          <FontAwesome
            name={iconName}
            size={20}
            color={selected ? selectedYellow : fontColorLight}
          />
        )}
        {iconFamily === fontFamilyTypes.materialCommunityIcons && (
          <MaterialCommunityIcons
            name={iconName}
            size={20}
            color={selected ? selectedYellow : fontColorLight}
          />
        )}
        {iconFamily === fontFamilyTypes.fontAwesome5 && (
          <FontAwesome5
            name={iconName}
            size={20}
            color={selected ? selectedYellow : fontColorLight}
          />
        )}

        <LSText
          text={name}
          color={selected ? selectedYellow : fontColorLight}
          variant={textVariant.button}
          customStyles={{ marginTop: 8 }}
        />
      </Pressable>
    </View>
  );
}

const style = StyleSheet.create({
  buttonContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  pressableStyles: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});

BottomNavButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  urlRoute: PropTypes.string.isRequired,
  iconFamily: PropTypes.string,
};

BottomNavButton.defaultProps = {
  iconFamily: null,
};
