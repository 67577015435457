import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useSharedValue } from "react-native-reanimated";
import { useSearchParams } from "react-router-dom";

import { apiGetForm } from "../../../shared/hoc/pagesWeb/incidentReporter/utils";
import { fontColorDark, fontColorLight } from "../../../theme/colors";
import MappableCard from "../../components/animated/MappableCard";
import MappableItem from "../../components/animated/MappableItem";
import { cardVariant } from "../../components/cards/Card";
import PageLayout from "../../components/layouts/PageLayout";
import LSSpinner from "../../components/spinner/LSSpinner";
import LSText, { textVariant } from "../../components/text/LSText";
import { pageAppTypes } from "../constants";

const pageTitle = "Report Setup";

export default function IncidentReportsEditing() {
  const [searchParams] = useSearchParams();
  const formID = searchParams.get("form");
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState({});
  const scrollY = useSharedValue(0);
  const [dragItemIndex, setDragItemIndex] = useState();
  const [dragOverItemIndex, setDragOverItemIndex] = useState();
  const [trashModalVisible, setTrashModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);

  const { questions } = form;

  const getForm = async () => {
    setLoading(true);
    const response = await apiGetForm(formID);
    setForm(response);
    setLoading(false);
  };

  const handleOnPress = () => {
    console.log("pressed");
  };

  useEffect(() => {
    console.log({ form });
  }, [form]);

  useEffect(() => {
    getForm();
  }, []);

  const handleDrop = () => {
    console.log("dropped", dragItemIndex, dragOverItemIndex);
  };

  return (
    <PageLayout pageAppType={pageAppTypes.incidentReports}>
      <LSText
        variant={textVariant.h1}
        text={pageTitle}
        color={fontColorLight}
      />
      {loading ? (
        <View style={styles.spinnerContainerStyles}>
          <LSSpinner />
        </View>
      ) : (
        <View>
          <>
            <LSText
              variant={textVariant.h3}
              text={form.name}
              color={fontColorLight}
            />
            <LSText
              variant={textVariant.h3}
              text={form.name}
              color={fontColorLight}
            />
            <div />
          </>
          {/* {questions?.map((question, idx) => {
            const { text, uuid } = question;
            return (
              <MappableCard
                handleOnPress={handleOnPress}
                uuid={uuid}
                questionText={`Q${idx + 1}: ${text}`}
                idx={idx}
                cardStyles={{ padding: 8, marginBottom: 16 }}
                setDragItemIndex={setDragItemIndex}
                setDragOverItemIndex={setDragOverItemIndex}
                idxObject={{ questionIdx: idx }}
                handleDrop={handleDrop}
              />
            );
          })} */}
        </View>
      )}
    </PageLayout>
  );
}

const styles = StyleSheet.create({
  spinnerContainerStyles: {
    height: "80vh",
    justifyContent: "center",
  },
});
