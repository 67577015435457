/* eslint-disable import/no-unresolved */
import { BASE_URL as WEB_BASE_URL } from "@env";
import { Platform } from "react-native";

const BASE_URL = Platform.OS === "web" ? WEB_BASE_URL : process.env.BASE_URL;

const API_BASE = `${BASE_URL}/api`;

// Auth
const AUTH_BASE = "/auth";
export const ME_USER_URL = `${API_BASE}${AUTH_BASE}/me`;
export const REGISTER_COMPANY_URL = `${API_BASE}${AUTH_BASE}/register-company`;
export const REGISTER_USER_URL = `${API_BASE}${AUTH_BASE}/register`;
export const LOGIN_USER_URL = `${API_BASE}${AUTH_BASE}/login`;
export const LOGOUT_USER_URL = `${API_BASE}${AUTH_BASE}/logout`;
export const REFRESH_USER_URL = `${API_BASE}${AUTH_BASE}/refresh-token`;
export const UPDATE_USER_URL = `${API_BASE}${AUTH_BASE}/update-user`;
export const GET_USERS = `${API_BASE}${AUTH_BASE}/get-users`;
export const ADD_COMPANY_USER = `${API_BASE}${AUTH_BASE}/add-company-user`;
export const ACTIVATE_COMPANY_USER = `${API_BASE}${AUTH_BASE}/activate-company-user`;

// Forms
const FORM_BASE = "/form";
export const CREATE_NEW_FORM = `${API_BASE}${FORM_BASE}/create-new-form`;
export const GET_MY_FORMS = `${API_BASE}${FORM_BASE}/get-forms`;
export const GET_MY_FORMS_SUMMARY = `${API_BASE}${FORM_BASE}/get-forms-summary`;
export const GET_MY_FORM = `${API_BASE}${FORM_BASE}/get-form`;
export const PUBLISH_MY_FORM = `${API_BASE}${FORM_BASE}/publish-form`;
export const UNPUBLISH_MY_FORM = `${API_BASE}${FORM_BASE}/unpublish-form`;
export const UPDATE_FORM = `${API_BASE}${FORM_BASE}/update-form`;
export const LOCK_MY_FORM = `${API_BASE}${FORM_BASE}/lock-form`;
export const UNLOCK_MY_FORM = `${API_BASE}${FORM_BASE}/unlock-form`;
export const CREATE_NEW_QUESTION = `${API_BASE}${FORM_BASE}/create-new-question`;
export const UPDATE_QUESTION = `${API_BASE}${FORM_BASE}/update-question`;
export const UPDATE_ANSWER_OPTION_QUESTION = `${API_BASE}${FORM_BASE}/update-answer-option-question`;
export const MAP_QUESTIONS = `${API_BASE}${FORM_BASE}/map-questions`;
export const DELETE_QUESTIONS = `${API_BASE}${FORM_BASE}/delete-questions`;
export const SUBMIT_FORM = `${API_BASE}${FORM_BASE}/submit-form`;
export const SUBMITTED_FORMS = `${API_BASE}${FORM_BASE}/submitted-forms`;
export const SUBMITTED_FORM = `${API_BASE}${FORM_BASE}/submitted-form`;
export const DOWNLOAD_PDF_REPORT = `${API_BASE}${FORM_BASE}/download-pdf-report`;
export const UPDATE_USER_NOTIFICATIONS = `${API_BASE}${FORM_BASE}/update-form-user-notifications`;

// Locations
const LOCATION_BASE = "/location";
export const ADD_LOCATION = `${API_BASE}${LOCATION_BASE}/add-location`;
export const GET_LOCATIONS = `${API_BASE}${LOCATION_BASE}/get-locations`;
