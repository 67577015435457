import { FontAwesome } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";

import { ADD_LOCATION, GET_LOCATIONS } from "../../../shared/apiUrls";
import {
  apiGetAuth,
  apiPostAuth,
} from "../../../shared/hoc/pagesWeb/incidentReporter/utils";
import { fontColorLight } from "../../../theme/colors";
import LSButton, { buttonVariants } from "../../components/buttons/LSButton";
import Card, { cardVariant } from "../../components/cards/Card";
import LSInput, { inputVariants } from "../../components/formInputs/LSInput";
import PageLayout from "../../components/layouts/PageLayout";
import LSModal from "../../components/modals/LSModal";
import LSSpinner from "../../components/spinner/LSSpinner";
import LSText, { textVariant } from "../../components/text/LSText";
import { pageAppTypes } from "../constants";

const pageTitle = "Manage Locations";
const newLocationObject = {
  name: "",
  address: "",
  city: "",
  state: "",
  zip: "",
};

const locationErrorsObject = {
  name: false,
  address: false,
  city: false,
  state: false,
  zip: false,
};

export default function LocationsSettings() {
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [newLocation, setNewLocation] = useState(newLocationObject);
  const [locationErrors, setLocationErrors] = useState(locationErrorsObject);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);

  const getLocations = async () => {
    const results = await apiGetAuth(GET_LOCATIONS);
    console.log({ results });
    setLocations(results);
    setLoading(false);
  };

  const handleAddNewLocation = () => {
    setAddModalVisible(true);
  };

  const validateLocationLocation = () => {
    let errors = false;

    const keys = Object.keys(newLocation);
    keys.forEach((key) => {
      if (!newLocation[key]) {
        setLocationErrors((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        errors = true;
      } else {
        setLocationErrors((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    });

    return errors;
  };

  const handleGenerateLocationPress = async () => {
    const errors = validateLocationLocation();

    if (errors) return;

    const response = await apiPostAuth(ADD_LOCATION, newLocation);
    if (response.name) {
      setLocations((prevState) => [response, ...prevState]);
      setNewLocation(newLocationObject);
      setAddModalVisible(false);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  return (
    <PageLayout pageAppType={pageAppTypes.locations}>
      <LSText
        variant={textVariant.h1}
        text={pageTitle}
        color={fontColorLight}
      />
      <View style={styles.headerContainerStyles}>
        <LSText
          variant={textVariant.h3}
          text="Locations"
          color="white"
          customStyles={{ marginBottom: 0, fontWeight: "bold" }}
        />
        <Pressable onPress={handleAddNewLocation}>
          <FontAwesome name="plus" size={25} color={fontColorLight} />
        </Pressable>
      </View>
      {loading ? (
        <View style={styles.spinnerContainerStyles}>
          <LSSpinner />
        </View>
      ) : (
        <View style={{ width: "100%" }}>
          {locations?.map((location) => {
            const { name, address, city, uuid } = location;
            return (
              <Pressable
                key={uuid}
                onPress={() => console.log("show location profile")}
              >
                <View style={styles.submissionContainer}>
                  <View style={styles.submissionContainerLeft}>
                    <LSText
                      variant={textVariant.h4}
                      text={name}
                      color="#ffffff"
                      customStyles={{ marginBottom: 8 }}
                    />
                    <LSText
                      variant={textVariant.text}
                      text={`${address}, ${city}`}
                      color="#ffffff"
                    />
                  </View>
                </View>
              </Pressable>
            );
          })}
        </View>
      )}

      <LSModal visible={addModalVisible} setVisible={setAddModalVisible}>
        <Card
          variant={cardVariant.primary}
          customStyles={{ width: 310, maxWidth: "100%" }}
        >
          <View style={{ width: "100%", marginBottom: 32 }}>
            <LSText
              text="Add New Location"
              variant={textVariant.h2}
              customStyles={{ marginBottom: 16, textAlign: "center" }}
            />
            <LSInput
              variant={inputVariants.primary}
              setter={setNewLocation}
              object={newLocation}
              name="name"
              placeholder="Name"
              errorObj={locationErrors}
              customStyles={styles.inputStyles}
            />
            <LSInput
              variant={inputVariants.primary}
              setter={setNewLocation}
              object={newLocation}
              name="address"
              placeholder="Address"
              errorObj={locationErrors}
              customStyles={styles.inputStyles}
            />
            <LSInput
              variant={inputVariants.primary}
              setter={setNewLocation}
              object={newLocation}
              name="city"
              placeholder="City"
              errorObj={locationErrors}
              customStyles={styles.inputStyles}
            />
            <LSInput
              variant={inputVariants.primary}
              setter={setNewLocation}
              object={newLocation}
              name="state"
              placeholder="State"
              errorObj={locationErrors}
              customStyles={styles.inputStyles}
            />
            <LSInput
              variant={inputVariants.primary}
              setter={setNewLocation}
              object={newLocation}
              name="zip"
              placeholder="Zip"
              errorObj={locationErrors}
              customStyles={styles.inputStyles}
            />
          </View>
          <View style={styles.buttonsContainer}>
            <LSButton
              onPress={() => setAddModalVisible(false)}
              text="Cancel"
              variant={buttonVariants.secondary}
              customStyles={{ marginRight: 16, flex: 1 }}
            />
            <LSButton
              onPress={handleGenerateLocationPress}
              variant={buttonVariants.primary}
              text="Save"
              customStyles={{ flex: 1 }}
            />
          </View>
        </Card>
      </LSModal>
    </PageLayout>
  );
}

const styles = StyleSheet.create({
  spinnerContainerStyles: {
    height: "80vh",
    justifyContent: "center",
  },
  headerContainerStyles: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  buttonsContainer: {
    flexDirection: "row",
    width: "100%",
  },
  inputStyles: { margin: 0, marginBottom: 16 },
  submissionContainer: {
    borderBottomColor: fontColorLight,
    borderBottomWidth: 1,
    flexDirection: "row",
    paddingTop: 16,
    paddingBottom: 8,
  },
  submissionContainerLeft: {
    flex: 1,
  },
});
